<template>
  <div class="newsLose">
    <el-dialog title="失效新闻" :visible.sync="dialogShow" @close="dialogClose">
      <el-table
        :data="loseData"
        stripe
        style="width: 100%;border: 1px solid #e8e8e8"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column prop="title" label="新闻标题">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="scope.row.title"
            >
              <p class="table_font" slot="reference">{{ scope.row.name }}</p>
            </el-popover></template
          >
        </el-table-column>
        <el-table-column label="新闻摘要">
          <template slot-scope="scope">
            <p class="table_font">
              {{ scope.row.desc }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="录入时间">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="scope.row.createTime"
            >
              <p class="table_font" slot="reference">
                {{ scope.row.createTime }}
              </p>
            </el-popover></template
          >
        </el-table-column>
        <el-table-column label="百度优化">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="200"
              trigger="hover"
              :content="scope.row.seoTitle"
              v-show="scope.row.newsNo"
            >
              <p class="table_font" slot="reference">SEO标题：{{ scope.row.seoTitle }}</p>
            </el-popover>
            <el-popover
              placement="right"
              width="200"
              trigger="hover"
              :content="scope.row.seoKeyword"
              v-show="scope.row.newsNo"
            >
              <p class="table_font" slot="reference">关键词：{{ scope.row.seoKeyword }}</p>
            </el-popover>
            <el-popover
              placement="right"
              width="200"
              trigger="hover"
              :content="scope.row.seoDesc"
              v-show="scope.row.newsNo"
            >
              <p class="table_font" slot="reference">描述：{{ scope.row.seoDesc }}</p>
            </el-popover></template
          >
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination flex"
        style="margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/news.js"
import utils from "@/utils/utils.js"
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loseData: [],
      rows: 5,
      page: 1,
      total: 0,
    }
  },
  computed: {
    dialogShow: {
      get: function() {
        return this.dialogVisible
      },
      set: function() {},
    },
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.rows = val
      this.getNewsList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getNewsList()
    },
    dialogClose() {
      this.$emit("dialogClose")
    },
    // 获取产品列表
    async getNewsList() {
      try {
        let condition = {
          pageInfo: {
            rows: this.rows,
            page: this.page,
          },
          reqData: {
            companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
            startTime: "",
            endTime: "",
            cateNo: "",
            isHide: 0,
          },
        }
        const res = await api.getNews(condition)
        if (res.data.code == 200) {
          if (res.data.result.list.length > 0) {
            let list = res.data.result.list
            list.forEach((item) => {
              item.createTime = utils.format(item.createTime, "yyyy-MM-dd HH:mm:ss")
            })
            // 数量不足，补齐
            for (let i = 1, num = this.rows - list.length; i <= num; i++) {
              list.push({})
            }
            this.loseData = list
            this.total = res.data.result.total
          } else {
            this.loseData = []
            this.total = 0
          }
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
  },
  mounted() {
    this.getNewsList()
  },
}
</script>

<style></style>
