import request from "@/utils/request.js"

export default {
  // 获取新闻
  getNews(data) {
    return request({
      url: "/news/getNews",
      method: "post",
      data: data,
    })
  },
  // 添加新闻
  addNews(data) {
    return request({
      url: "/news/addNews",
      method: "post",
      data: data,
    })
  },
  // 更新新闻信息
  updateNews(data) {
    data.address = JSON.parse(localStorage.getItem("userInfo")).address
    data.company = JSON.parse(localStorage.getItem("userInfo")).company
    return request({
      url: "/news/updateNews",
      method: "post",
      data: data,
    })
  },
  // 获取新闻分类
  getCate() {
    return request({
      url: "/news/getCate?companyId=" + JSON.parse(localStorage.getItem("userInfo")).companyId,
      method: "get",
    })
  },
  // 添加新闻分类
  addCate(obj) {
    return request({
      url: "/news/addCate",
      method: "post",
      data: obj,
    })
  },
  // 修改新闻分类
  updateCate(obj) {
    return request({
      url: "/news/updateCate",
      method: "post",
      data: obj,
    })
  },
  // 删除新闻分类
  deleteCate(obj) {
    return request({
      url: "/news/deleteCate",
      method: "post",
      data: obj,
    })
  },
}
