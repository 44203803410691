<template>
  <div class="newsAdd">
    <NewsEdit ref="newsEdit" :picNo="picNo">
      <div class="flex" slot="form_submit">
        <el-button type="primary" @click="addNews" :loading="saveLoad"
          >新增新闻</el-button
        >
      </div>
      <div class="flex" slot="ditor_submit">
        <el-button type="primary" @click="saveDitor" :loading="saveLoad"
          >保存详情</el-button
        >
      </div>
      <div class="flex" slot="seo_submit">
        <el-button type="primary" @click="saveSeo" :loading="saveLoad"
          >保存TDK</el-button
        >
      </div>
    </NewsEdit>
  </div>
</template>

<script>
import NewsEdit from "./modules/NewsEdit.vue";
import api from "@/api/news.js";
import utils from "@/utils/utils.js";
export default {
  components: {
    NewsEdit,
  },
  data() {
    // 获取随机生成的图片编号
    let picNo = utils.UUID(
      JSON.parse(localStorage.getItem("userInfo")).companyId + "_"
    );
    return {
      picNo: picNo,
      newsNo: "",
      // 按钮状态
      saveLoad: false,
    };
  },
  methods: {
    // 新增案例
    async addNews() {
      // 获取案例信息
      let ruleForm = this.$refs.newsEdit.ruleForm;
      let cateList = this.$refs.newsEdit.cateList;
      if (!ruleForm.name) {
        this.$message({
          type: "error",
          message: "请填写新闻标题！",
          duration: 800,
        });
        return;
      }
      if (!ruleForm.desc) {
        this.$message({
          type: "error",
          message: "请填写新闻摘要！",
          duration: 800,
        });
        return;
      }
      if (!ruleForm.pic) {
        this.$message({
          type: "error",
          message: "请上传缩略图！",
          duration: 800,
        });
        return;
      }
      if (cateList != [] && !ruleForm.cateNo) {
        this.$message({
          type: "error",
          message: "请选择分类！",
          duration: 800,
        });
        return;
      }
      this.saveLoad = true;
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        name: ruleForm.name,
        pic: ruleForm.pic,
        cateNo: ruleForm.cateNo,
        desc: ruleForm.desc,
        author: ruleForm.author,
        source: ruleForm.source,
        cateName: ruleForm.cateName,
        isShow: false,
        isTop: false,
        isHide: true,
      };
      try {
        const res = await api.addNews(condition);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
            duration: 1000,
          });
          this.newsNo = res.data.result.newsNo;
        } else {
          this.$message.error("网络故障！");
        }
        this.saveLoad = false;
      } catch (error) {
        this.$message.error(error);
        this.saveLoad = false;
      }
    },
    // 保存案例详情
    async saveDitor() {
      this.saveLoad = true;
      // 获取案例信息
      let ruleForm = this.$refs.newsEdit.ruleForm;
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        newsNo: this.newsNo,
        ditorHtml: JSON.stringify(ruleForm.ditorHtml),
      };
      try {
        const res = await api.updateNews(condition);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          });
        } else {
          this.$message.error("网络故障！");
        }
        this.saveLoad = false;
      } catch (error) {
        this.$message.error(error);
        this.saveLoad = false;
      }
    },
    // 保存案例seo信息
    async saveSeo() {
      this.saveLoad = true;
      // 获取案例信息
      let ruleForm = this.$refs.newsEdit.ruleForm;
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        newsNo: this.newsNo,
        seoTitle: ruleForm.seoTitle,
        seoKeyword: ruleForm.seoKeyword,
        seoDesc: ruleForm.seoDesc,
      };
      try {
        const res = await api.updateNews(condition);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          });
        } else {
          this.$message.error("网络故障！");
        }
        this.saveLoad = false;
      } catch (error) {
        this.$message.error(error);
        this.saveLoad = false;
      }
    },
  },
};
</script>

<style></style>
